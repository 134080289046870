import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import shortKey from "vue3-shortkey";
import "./assets/main.css";

import "highlight.js/styles/default.css";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(shortKey, { prevent: ["input", "textarea"] })
  .mount("#app");
