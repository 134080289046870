import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/number-drawer",
    name: "NumberDrawer",
    component: () => import("../views/NumberDrawer.vue"),
  },
  {
    path: "/name-drawer",
    name: "NameDrawer",
    component: () => import("../views/NameDrawer.vue"),
  },
  {
    path: "/team-drawer",
    name: "TeamDrawer",
    component: () => import("../views/TeamDrawer.vue"),
  },
  {
    path: "/linked-lists",
    name: "LinkedLists",
    component: () => import("../views/LinkedLists.vue"),
  },
  {
    path: "/timer",
    name: "TimerPage",
    component: () => import("../views/TimerPage.vue"),
  },
  {
    path: "/chronometer",
    name: "ChronometerPage",
    component: () => import("../views/ChronometerPage.vue"),
  },
  {
    path: "/contador-caracteres",
    name: "CharacterCounter",
    component: () => import("../views/CharacterCounter.vue"),
  },
  {
    path: "/comparador-texto",
    name: "TextComparer",
    component: () => import("../views/TextComparer.vue"),
  },
  {
    path: "/wheel-of-fortune",
    name: "WheelOfFortune",
    component: () => import("../views/WheelOfFortune.vue"),
  },
  {
    path: "/syntax-highlighter",
    name: "SyntaxHighlighter",
    component: () => import("../views/SyntaxHighlighter.vue"),
  },
  {
    path: "/lorem-ipsum",
    name: "LoremIpsum",
    component: () => import("../views/LoremIpsumGenerator.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
